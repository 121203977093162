import {
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  newArrFive,
  newArrSix,
  newArrSeven,
  manBalanceVideo,
  manBalanceFirstPoster,
  manBalanceAnotherVideo,
  manBalanceSecondPoster,
  flexBalanceAnotherVideo,
  flexBalanceFirstPoster,
  boxIcon,
  manbalanceOne,
  manbalanceTwo,
  manbalanceThree,
  flexbalanceOne,
  flexbalanceTwo,
  flexbalanceThree,
  fembalanceOne,
  fembalanceTwo,
  fembalanceThree,
  alcobalanceOne,
  alcobalanceTwo,
  alcobalanceThree,
  bodybalanceOne,
  bodybalanceTwo,
  bodybalanceThree,
  libidofortisOne,
  libidofortisTwo,
  libidofortisThree,
  erokingOne,
  erokingTwo,
  erokingThree,
  contractIcon,
  shopIcon,
  manbalanceImg,
  reviewOne,
  reviewTwo,
  reviewThree,
  reviewFour,
} from "../assets/images/index";











// ===========

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Главная",
    link: "/",
  },
  {
    _id: 1002,
    title: "Каталог",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "О нас",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Акции",
    link: "/contact",
  },



];

export const questionsForMans = [
  {
    id: 1,
    questionText: 'Сколько вам лет?',
    options: ['18-24 года', '25-34 года', '35-44 года', '45+ года'],
  },
  {
    id: 2,
    questionText: 'у вас есть проблемы с частым перееданием?',
    options: ['Нет', 'Редко', 'Да', 'Часто'],
  },
  {
    id: 3,
    questionText: 'Как часто вы ходите в туалет по маленькому в день?',
    options: ['1-2 раза', '3-4 раза', '5-6 раза', '7+ раза'],
  },
  {
    id: 4,
    questionText: 'Какой у вас вес?',
    options: ['50-65 кг', '80-90 кг', '+ 100 кг', '70-75 кг'],
  },

  {
    id: 5,
    questionText: 'Бывали проблемы во время полового акта?',
    options: ['Да', 'Нет', 'Не было', 'Довольно часто'],
  },
  {
    id: 5,
    questionText: 'Сколько в среднем длиться половой акт?',
    options: ['10-15 мин', '20-25 мин', 'Не знаю', '1-5 мин'],
  },
  {
    id: 6,
    questionText: 'У вас часто болят суставы ног?',
    options: ['Нет', 'Да', 'Не часто', 'Иногда'],
  },
  {
    id: 7,
    questionText: 'Присутствует резкая боль в движении и покое после прогулки?',
    options: ['Почти не бывает', 'Да', 'Иногда', 'Нет'],
  },
  {
    id: 8,
    questionText: 'Задумывались ли вы сбросить вес ?',
    options: ['Не думал', 'Бывало', 'Да', 'Нет'],
  },
];

export const questionsForWomans = [
  {
    id: 1,
    questionText: 'Сколько вам лет?',
    options: ['18-24 года', '25-34 года', '35-44 года', '45+ года'],
  },
  {
    id: 2,
    questionText: 'у вас есть проблемы с частым перееданием?',
    options: ['Нет', 'Редко', 'Да', 'Часто'],
  },
  {
    id: 3,
    questionText: 'Как часто вы ходите в туалет по маленькому в день?',
    options: ['1-2 раза', '3-4 раза', '5-6 раза', '7+ раза'],
  },
  {
    id: 4,
    questionText: 'Какой у вас вес?',
    options: ['50-65 кг', '80-90 кг', '+ 100 кг', '70-75 кг'],
  },
  {
    id: 5,
    questionText: 'У вас часто болят суставы ног?',
    options: ['Нет', 'Да', 'Не часто', 'Иногда'],
  },
  {
    id: 6,
    questionText: 'Присутствует резкая боль в движении и покое после прогулки ?',
    options: ['Почти не бывает', 'Да', 'Иногда', 'Нет'],
  },
  {
    id: 7,
    questionText: 'Задумывались ли вы сбросить вес?',
    options: ['Не думал', 'Бывало', 'Да', 'Нет'],
  },
  {
    id: 8,
    questionText: 'Бывают запоры?',
    options: ['Не думал', 'Бывало', 'Да', 'Нет'],
  },
  {
    id: 9,
    questionText: 'Присутвует резкая боль при беге?',
    options: ['Не думал', 'Бывало', 'Да', 'Нет'],
  },
  // Добавьте дополнительные вопросы по необходимости
];



// =================== NavBarList End here ======================

export const ItemsBox = [
  {
    img: boxIcon,
    description: "Доставка по всем городам Казахстана",
    title: "Доставка"
  },
  {
    img: shopIcon,
    title: "Продавец №1",
    description: "в своей категории по всему Казахстану "
  },
  {
    img: contractIcon,
    description: "максимальное качество для здоровья.",
    title: "Премимум бренд"
  }
];






// =================== SALES and PROMOCODES Start here ==============
export const promoCodes = [

  { promo: 'VITABONUS', discount: -0.1, },


  // Добавьте дополнительные промо-коды при необходимости
];


// =================== SALES and PROMOCODES END here ==============
export const reviews = [
  {
    id: 1,
    name: 'Лера Дубкина',
    job: 'Бухгалтер',
    image:
      reviewOne,
    text:
      'Мне 50 лет, и я тоже еле ходила. Когда я был молода, я не думала, что такое может со мной пройзойти. Косточка в суставе выросла большой, и мои ноги не могли поместиться ни в одну обувь. Было так больно, что я не носила тапочки дома. Однажды я зашла в магазин за обувью и примерила туфли, они не поместились. Я заплакала от боли, подошел продавец и спросил, Что случилось. Я сказала, что у меня болит нога, я не знаю, что делать. Он предложил мне "Flex-balance". Он сказал, что такая же история произошла и с ним, а теперь он носит туфли с тонким клювом. Поверив ему, я пришла домой и заказала. Помог! Я вам тоже советую. ',
  },
  {
    id: 2,
    name: 'Алибек Жолдасов',
    job: 'Менеджер',
    image:
      reviewTwo,
    text:
      'Я, как человек, предпочитающий естественные методы, был впечатлен составом препарата. После использования заметил значительное улучшение потенции без каких-либо побочных эффектов. LibidoFortis  отличный выбор для тех, кто ценит свое здоровье.      ',
  },
  {
    id: 3,
    name: 'Анастасия Жучкова',
    job: 'Домохозяйка',
    image:
      reviewThree,
    text:
      'Мы вместе с моим мужем решили попробовать препарат ManBalance. Результаты просто поразительны! Новые ощущения, большая близость. Это как возвращение к началу отношений. Спасибо, что вернули нам страсть!',
  },
  {
    id: 4,
    name: 'Виктория Ли',
    job: 'Кассир',
    image:
      reviewFour,
    text:
      'Прежде чем начал принимать, мой вес был 90 кг. В молодости я всегда была стройной, с возрастом росли и живот, и жир в боках. Когда мне было 35 лет, я понял, что должен быстро взять себя в руки. Несмотря на то, что я тренировался почти год, я не смог прийти в свою нормальную форму. Затем я добавил BodyBalance в свой рацион, и через несколько недель я снова стал человеком, которого знал с юных лет. Теперь я сохраняю свою форму, а BodyBalance у меня теперь всегда с собой.',
  },
];
// =================== PaginationItems Start here ===============

export const paginationItems = [
  {
    _id: "212257",
    img: newArrOne,
    productName: "ManBalance",
    price: "74250",

    color: "средство для мужчин",
    badge: true,

    des: "ManBalance: Инновационная Поддержка Мужской Сексуальной Жизни"
  },

  {
    _id: "212255",
    img: newArrTwo,
    productName: "BodyBalance",
    price: "74250",

    color: "средство для быстрого похудения",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
  },
  {
    _id: "212253",
    img: newArrThree,
    productName: "AlcoBalance",
    price: "74250",
    color: "средство от алкозависимости",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
  },
  {
    _id: "212256",
    img: newArrFour,
    productName: "FlexBalance",
    price: "74250",
    color: "средство от болей в суставах",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
  },
  {
    _id: "212254",
    img: newArrFive,
    productName: "EroKing",
    price: "74250",
    color: "средство для мужчин",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
  },
  {
    _id: "212251",
    img: newArrSix,
    productName: "LibidoFortis",
    price: "74250",
    color: "средство для мужчин",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
  },
  {
    _id: "212252",
    img: newArrSeven,
    productName: "FemBalance",
    price: "74250",
    color: "средство для женщин",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
  },

];
// =================== PaginationItems End here =================






export const ProductsDetailItems = [
  {
    _id: "212257",
    img: newArrOne,
    productName: "ManBalance",
    capsule: '/capsules/man.png',
    price: "74250",
    // priceSec:"97200",
    // priceThr:"129600",
    color: "средство для мужчин",
    badge: true,
    video: manBalanceVideo,
    videoPoster: manBalanceFirstPoster,
    anotherVideo: manBalanceAnotherVideo,
    anotherVideoPoster: manBalanceSecondPoster,
    des: "ManBalance - это передовой препарат, разработанный специально для поддержания и улучшения мужской сексуальной жизни. ",
    des1: 'Уникальное сочетание природных компонентов, таких как Спирулина, Экстракт Маки перуанской, Корень женьшеня, делает ManBalance мощным средством для решения различных проблем в сфере сексуального здоровья мужчины.',
    des2: 'Эффект заметен сразу после первого применения, за 4 недели вы получите желаемый результат. Улучшает качество эрекции и продлевает длительность полового акта,  восстановите свою сексуальную активность с ManBalance уже сегодня!"',
    rules: [
      { li: "Вы забудете о беспокойствах и неудовлетворенности в интимной жизни, обретете уверенность и радость от каждого момента. Ваша энергия и жизненный настрой будут вновь на пике, позволяя наслаждаться полноценными и яркими отношениями.      " },
      { li: "Исправите свои сомнения и страхи, которые мешают вам полноценно наслаждаться жизнью. Верните себе уверенность и радость, освободившись от стеснительности и неуверенности. Получите возможность раскрыть свой истинный потенциал и наслаждаться каждым моментом жизни.      " },
      { li: "Восстановите свою сексуальную жизнь и интимные отношения, достигнув новых высот удовольствия и близости. Почувствуйте силу и энергию, которые помогут вам преодолеть любые препятствия на пути к счастливой и удовлетворенной жизни.      " },
      { li: "Получите настоящее удовлетворение от интимной близости, переживая яркие и незабываемые моменты с партнером. Ощутите полноту жизни и радость от каждого совместного момента, обретя уверенность и гармонию в отношениях." },
    ],
    description: [
      "Регулирует процессы эякуляции, продлевая продолжительность полового акта.",
      "Пониженный тестостерон: Повышает уровень тестостерона, улучшая сексуальное влечение и энергию.",
      "Простатит: Обладает противовоспалительными свойствами, помогая предотвращать и облегчать симптомы простатита.",
      "Ухудшение мужского здоровья: Улучшает эрекцию, делая ее более твердой и стойкой.",
    ],
    customerReviews:
      [
        {
          id: 1,
          name: "Нуржан Казыбеков",
          date: "22 июня 2023",
          content: "Препарат ManBalance реально помог. Улучшилось общее самочувствие и добавилось сил! ",
          helpfulCount: 12,
          image: manbalanceImg,
        },
        {
          id: 2,
          name: "Александра Ермакова",
          date: "12 июля 2023",
          content: "Мой муж стал использовать ManBalance и результаты нас обоих устроили. Спасибо!",
          helpfulCount: 19,
        },
        {
          id: 3,
          name: "Тимур Рустемов",
          date: "5 августа 2023",
          content: "Начал принимать ManBalance по совету друга. Заметил положительные изменения уже через неделю.",
          helpfulCount: 7,
        },
        // Add more review objects as needed
      ],
    listItems: [
      { li: "Низкая либидо" },
      { li: "Эректильная дисфункция" },
      { li: "Простатит" },
      { li: "Ослабленная потенция" },
      { li: "Потеря сексуального влечения" },
    ],
    priceList: [
      {
        price: "74250",
        desc: '',
        pack: manbalanceOne,
        index: 1,
      },
      {
        price: "86400",
        desc: '',
        undiscount: "172800",
        pack: manbalanceTwo,
        index: 2,
      },
      {
        price: "115200",
        desc: '',
        undiscount: "230400",
        pack: manbalanceThree,
        index: 3,
      }
    ]
  },
  {
    _id: "212255",
    img: newArrTwo,
    productName: "BodyBalance",
    capsule: '/capsules/flex.png',
    price: "74250",
    color: "средство для быстрого похудения",
    badge: true,
    des: "BodyBalance - это эффективное средство для быстрого похудения, разработанное на основе натуральных компонентов.",
    des1: "Благодаря инновационной формуле, BodyBalance активизирует метаболизм, снижает аппетит и ускоряет процесс сжигания жировых запасов.Уникальное сочетание природных компонентов, таких как инозитол, метионин, D,L-холин битартрат; агент антислеживающий: тальк пищевой делает Body-Balance мощным средством для сжигание лишнего веса .",
    des2: "Начните свой путь к стройной фигуре уже сегодня с BodyBalance! Эффект заметен сразу после первого применения, первую неделю вы почуствуете что вам хочется есть меньше и сжир будет сжигаться желаемый результат не заставит вас долго ждать.",
    description: [
      "Активизирует метаболизм, способствуя быстрому сжиганию жировых запасов.",
      "Снижает аппетит, помогая контролировать калорийный прием.",
      "Ускоряет процесс похудения и формирования стройной фигуры.",
    ],
    rules: [
      { li: "Вы забудете о постоянных сомнениях в своей внешности и неудовлетворенности своим телом. Получите возможность наслаждаться каждым днем, чувствуя себя уверенно и привлекательно, благодаря стройной и подтянутой фигуре, которую вы сами себе создали.      " },
      { li: "Исправите все проблемы с фигурой, создавая идеальное тело, которое будет радовать глаз и приносить уверенность. Почувствуйте себя превосходно в любой ситуации, зная, что ваш облик идеален, а вы выглядите на все сто!      " },
      { li: "Восстановите свою уверенность и радость от собственного отражения, достигнув идеальной формы тела и привлекательного внешнего вида. Обретите гармонию и здоровье, избавившись от лишнего веса и недостатков фигуры, что поможет вам чувствовать себя лучше и счастливее.      " },
      { li: "Получите возможность изменить свою жизнь к лучшему, достигнув идеальной формы и здоровья. Получите новый уровень уверенности и самоуважения, благодаря привлекательной и стройной фигуре, которую сможете с гордостью демонстрировать." },
    ],
    customerReviews:
      [
        {
          id: 1,
          name: "Марат Жолдасов",
          date: "12 января 2024",
          content: "С началом приёма BodyBalance я почувствовал новый прилив сил. Вес начал уходить, а жизненный тонус заметно повысился.",
          helpfulCount: 12,
        },
        {
          id: 2,
          name: "Светлана Рязанова",
          date: "27 февраля 2024",
          content: "BodyBalance помог мне восстановить контроль над своим весом после новогодних праздников. Это был настоящий прорыв для меня!",
          helpfulCount: 9,
        },
        {
          id: 3,
          name: "Айдар Нургалиев",
          date: "6 марта 2024",
          content: "Этот препарат стал моим верным помощником на пути к здоровому телу. Рекомендую BodyBalance всем, кто сталкивается с затруднениями в похудении.",
          helpfulCount: 5,
        },
        {
          id: 4,
          name: "Екатерина Иванова",
          date: "20 декабря 2023",
          content: "С BodyBalance я забыла о диетах. Теперь я могу есть все, что люблю, и при этом худею. Это чудо!",
          helpfulCount: 18,
        },
        {
          id: 5,
          name: "Тимур Валеев",
          date: "15 ноября 2023",
          content: "За короткий срок BodyBalance помог мне избавиться от лишних килограммов, которые мешали мне наслаждаться жизнью.",
          helpfulCount: 26,
        },
        // Add more review objects as needed
      ],

    listItems: [
      { li: "Медленный метаболизм" },
      { li: "Недостаток энергии" },
      { li: "Набор лишнего веса" },
      { li: "Трудности в снижении веса" },
      { li: "Отложения жира" },
    ],
    priceList: [
      {
        price: "74250",
        desc: '',
        pack: bodybalanceOne,
        index: 1,
      },
      {
        price: "86400",
        desc: '',
        pack: bodybalanceTwo,
        undiscount: "172800",
        index: 2,
      },
      {
        price: "115200",
        desc: '',
        pack: bodybalanceThree,
        undiscount: "230400",
        index: 3,
      }
    ]

  },
  {
    _id: "212253",
    img: newArrThree,
    productName: "AlcoBalance",
    capsule: '/capsules/alco.png',
    price: "74250",
    color: "средство от алкозависимости",
    badge: true,
    des: "AlcoBalance - это инновационное средство для борьбы с алкогольной зависимостью.",
    des1: "Специально разработанная формула AlcoBalance помогает уменьшить желание к употреблению алкоголя, улучшает общее самочувствие и способствует восстановлению функций организма после злоупотребления алкоголем. Уникальное сочетание природных компонентов, таких как экстракт корня кудзу (лобулярная пуэрария) – снижает тягу к алкоголю, экстракт листьев артишока – поддерживает детоксикацию и оздоровление печени, а также Габа Алишань – формирует полное безразличие к алкоголю. Комплекс из Гинкго билоба, который предотвращает отмирание клеток мозга, и Чай матча делает AlcoBalance мощным средством от алкогольной зависимости. Дополнительные компоненты капсул включают желатин, микрокристаллическую целлюлозу, янтарную кислоту, витамин С (аскорбиновую кислоту) и антисептик стеарат магния.",
    des2: "Пырименяйте AlcoBalance для восстановления здоровья и улучшения качества жизни!",
    description: [
      "Уменьшает желание к употреблению алкоголя, помогая преодолеть зависимость.",
      "Улучшает общее самочувствие и физическое состояние организма.",
      "Поддерживает процессы восстановления после злоупотребления алкоголем.",
    ],
    rules: [
      { li: "Вы забудете о постоянном борении с желанием алкоголя, ваше здоровье улучшится, семейные отношения станут крепче, а жизнь наполнится яркими и радостными моментами. Вы станете хозяином своей судьбы и насладитесь каждым днем в полной мере.      " },
      { li: "Вы исправите свои привычки и отношение к алкоголю, уменьшите желание его употреблять, улучшите свое физическое и эмоциональное состояние, вернетесь к более полноценной жизни с семьей и близкими, а также обретете уверенность в своем будущем.      " },
      { li: "Вы восстановите свое здоровье и благополучие, вернетесь к активной и радостной жизни, улучшите свои отношения с семьей и близкими, обретете контроль над своими привычками и эмоциями, станете более уверенным и счастливым человеком." },
      { li: "Получите возможность преобразить свою жизнь с помощью нашего препарата. Почувствуйте радость от свободы от вредных привычек, верните себе здоровье и энергию. Наслаждайтесь каждым моментом, будьте уверены в завтрашнем дне и своем благополучии." },


    ],
    customerReviews:
      [
        {
          id: 1,
          name: "Артем Игнатьев",
          date: "5 февраля 2024",
          content: "AlcoBalance помог мне справиться с тягой к алкоголю. Чувствую себя намного лучше и полон сил.",
          helpfulCount: 8,
        },
        {
          id: 2,
          name: "Светлана Медведева",
          date: "12 марта 2024",
          content: "Препарат действительно работает. Мой муж перестал пить, и наша семья снова счастлива.",
          helpfulCount: 14,
        },
        {
          id: 3,
          name: "Николай Васильев",
          date: "25 января 2024",
          content: "С AlcoBalance моя жизнь изменилась. Я больше не зависим от алкоголя и начал новую жизнь.",
          helpfulCount: 5,
        },
        {
          id: 4,
          name: "Дарина Алексеева",
          date: "9 марта 2024",
          content: "Замечательное средство для борьбы с алкоголизмом. Я смогла оставить пить благодаря AlcoBalance.",
          helpfulCount: 11,
        },
        {
          id: 5,
          name: "Ирина Степанова",
          date: "15 февраля 2024",
          content: "Это средство помогло мне в трудные времена. Благодарю разработчиков за AlcoBalance!",
          helpfulCount: 7,
        },
        // Add more review objects as needed
      ],

    listItems: [
      { li: "Алкогольная зависимость" },
      { li: "Алкогольные кризы" },
      { li: "Социальная деградация" },
      { li: "Риски здоровью" },
      { li: "Семейные проблемы" },
    ],
    priceList: [
      {
        price: "74250",
        desc: '',
        pack: alcobalanceOne,

        index: 1,
      },
      {
        price: "86400",
        desc: '',
        pack: alcobalanceTwo,
        undiscount: "172800",
        index: 2,
      },
      {
        price: "115200",
        desc: '',
        pack: alcobalanceThree,
        undiscount: "230400",
        index: 3,
      }
    ]

  },
  {
    _id: "212256",
    img: newArrFour,
    productName: "Flex-Balance",
    capsule: '/capsules/flex.png',
    price: "74250",
    video: flexBalanceAnotherVideo,
    videoPoster: flexBalanceFirstPoster,
    // discount:"",
    color: "средство от болей в суставах",
    badge: true,
    des: "FlexBalance - это натуральное средство для снятия болей в суставах и укрепления суставной ткани. Уникальное сочетание природных компонентов, таких как Коллаген, гиалуроновая кислота и витамин С влияют на здоровье опорно-двигательного аппарата делает Flex-Balance мощным средством для решения различных проблем cуставами.",
    des1: "Благодаря уникальной формуле, FlexBalance помогает улучшить подвижность суставов, снять воспаление и уменьшить болевые ощущения.",
    des2: "Восстановите здоровье своих суставов с FlexBalance!",
    description: [
      "Улучшает подвижность суставов и укрепляет суставную ткань.",
      "Снимает воспаление и уменьшает болевые ощущения.",
      "Способствует восстановлению здоровья и функциональности суставов.",
    ],
    customerReviews:
      [
        {
          id: 1,
          name: "Елена Сергеева",
          date: "15 февраля 2024",
          content: "FlexBalance помог мне избавиться от болей в коленях, которые мешали мне заниматься спортом. Очень благодарна!",
          helpfulCount: 26,
        },
        {
          id: 2,
          name: "Игорь Николаев",
          date: "3 марта 2024",
          content: "После двух недель использования FlexBalance мои суставы стали значительно лучше. Рекомендую данный продукт!",
          helpfulCount: 14,
        },
        {
          id: 3,
          name: "Мария Иванова",
          date: "21 января 2024",
          content: "FlexBalance стал настоящим спасением для моих суставов. Теперь могу заниматься садоводством без дискомфорта.",
          helpfulCount: 19,
        },
        {
          id: 4,
          name: "Азамат Қали",
          date: "28 февраля 2024",
          content: "Благодарю за FlexBalance! Чувствую облегчение и подвижность в суставах, которых не было уже долгое время.",
          helpfulCount: 22,
        },
        // Add more review objects as needed
      ],

    listItems: [
      { li: "Артрит" },
      { li: "Остеоартроз" },
      { li: "Ревматоидный артрит" },
      { li: "Бурсит" },
      { li: "Травмы суставов" },
    ],
    priceList: [
      {
        price: "74250",
        desc: '',
        pack: flexbalanceOne,

        index: 1,
      },
      {
        price: "86400",
        desc: '',
        pack: flexbalanceTwo,
        undiscount: "172800",
        index: 2,
      },
      {
        price: "115200",
        desc: '',
        pack: flexbalanceThree,
        undiscount: "230400",
        index: 3,
      }
    ]

  },
  {
    _id: "212254",
    img: newArrFive,
    productName: "EroKing",
    capsule: '/capsules/ero.png',
    price: "74250",
    color: "средство для мужчин",
    badge: true,
    des: "EroKing - это высокоэффективное средство для улучшения мужской сексуальной функции и повышения потенции.Уникальное сочетание природных компонентов, таких как Спирулина, Экстракт Маки перуанской, Корень женьшеня, делает EroKing мощным средством для решения различных проблем в сфере сексуального здоровья мужчины.",
    des1: "Специально разработанная формула EroKing активизирует кровообращение в области малого таза, усиливает эрекцию и улучшает сексуальные ощущения.",
    des2: "Почувствуйте себя настоящим королем в постели с EroKing!",
    description: [
      "Усиливает эрекцию и повышает потенцию.",
      "Повышает сексуальное влечение и улучшает сексуальные ощущения.",
      "Способствует улучшению сексуальной функции и увеличению выносливости.",
    ],
    rules: [
      { li: "Нормализует гормональный фон и поддерживает женское здоровье." },
      { li: "Улучшает состояние кожи, волос и ногтей." },
      { li: "Повышает уровень энергии и жизненных сил." },
      { li: "Повышает уровень энергии и жизненных сил." },
    ],
    customerReviews:
      [
        {
          id: 1,
          name: "Айдар Нурмагамбетов",
          date: "15 февраля 2024",
          content: "После двух недель применения EroKing я заметил значительные улучшения. Очень доволен результатом!",
          helpfulCount: 22,
        },
        {
          id: 2,
          name: "Владимир Еремин",
          date: "3 марта 2024",
          content: "EroKing помог мне вернуть уверенность в себе. Рекомендую всем, кто столкнулся с подобными проблемами!",
          helpfulCount: 17,
        },
        {
          id: 3,
          name: "Екатерина Иванова",
          date: "21 января 2024",
          content: "Мой муж стал использовать EroKing, и мы оба заметили изменения к лучшему. Спасибо за такой продукт!",
          helpfulCount: 35,
        },
        {
          id: 4,
          name: "Тимур Болатович",
          date: "28 января 2024",
          content: "Препарат EroKing реально работает. Улучшилась не только потенция, но и общее состояние.",
          helpfulCount: 29,
        },
        // Add more review objects as needed
      ],

    listItems: [
      { li: "Низкая либидо" },
      { li: "Эректильная дисфункция" },
      { li: "Простатит" },
      { li: "Ослабленная потенция" },
      { li: "Потеря сексуального влечения" },
    ],
    priceList: [
      {
        price: "74250",
        desc: '',
        pack: erokingOne,

        index: 1,

      },
      {
        price: "74250",
        desc: '',
        pack: erokingTwo,
        undiscount: "129600",
        index: 2,
      },
      {
        price: "97200",
        desc: '',
        pack: erokingThree,
        undiscount: "194400",
        index: 3,
      }
    ]
  },
  {
    _id: "212251",
    img: newArrSix,
    productName: "LibidoFortis",
    capsule: '/capsules/libido.png',
    price: "74250",
    color: "средство для мужчин",
    badge: true,
    des: "LibidoFortis - это натуральный препарат для улучшения мужской потенции и повышения сексуальной активности.Уникальное сочетание природных компонентов, таких как Спирулина, Экстракт Маки перуанской, Корень женьшеня, делает LibidoFortis мощным средством для решения различных проблем в сфере сексуального здоровья мужчины.",
    des1: "Специально разработанная формула LibidoFortis активизирует кровообращение в области малого таза, усиливает эрекцию и улучшает сексуальные ощущения.",
    des2: "Получите максимум удовольствия от сексуальных отношений с LibidoFortis!",
    description: [
      "Увеличивает сексуальное влечение и желание.",
      "Усиливает эрекцию и продлевает продолжительность полового акта.",
      "Повышает сексуальную активность и улучшает качество сексуальных отношений.",
    ],
    rules: [
      { li: "Нормализует гормональный фон и поддерживает женское здоровье." },
      { li: "Улучшает состояние кожи, волос и ногтей." },
      { li: "Повышает уровень энергии и жизненных сил." },
      { li: "Повышает уровень энергии и жизненных сил." },
    ],
    customerReviews:
      [
        {
          id: 1,
          name: "Артур Есенбаев",
          date: "5 ноября 2023",
          content: "LibidoFortis действительно помогает. Чувствую себя гораздо лучше и увереннее.",
          helpfulCount: 12,
        },
        {
          id: 2,
          name: "Ирина Волкова",
          date: "22 января 2024",
          content: "Мой муж начал принимать LibidoFortis, и наши отношения значительно улучшились. Спасибо за ваш продукт!",
          helpfulCount: 27,
        },
        {
          id: 3,
          name: "Олег Киреев",
          date: "17 февраля 2024",
          content: "С LibidoFortis я обрел новую жизнь. Эффективность на высоте!",
          helpfulCount: 19,
        },
        // Add more review objects as needed
      ],

    listItems: [
      { li: "Низкая либидо" },
      { li: "Эректильная дисфункция" },
      { li: "Простатит" },
      { li: "Ослабленная потенция" },
      { li: "Потеря сексуального влечения" },
    ],
    priceList: [
      {
        price: "74250",
        desc: '',
        pack: libidofortisOne,
        index: 1,

      },
      {
        price: "74250",
        desc: '',
        pack: libidofortisTwo,
        undiscount: "129600",
        index: 2,
      },
      {
        price: "97200",
        desc: '',
        pack: libidofortisThree,
        undiscount: "194400",
        index: 3,
      }
    ]
  },
  {
    _id: "212252",
    img: newArrSeven,
    productName: "FemBalance",
    capsule: '/capsules/flex.png',
    price: "74250",
    color: "средство для женщин",
    badge: true,
    des: "FemBalance - это инновационное средство для поддержания женского здоровья и улучшения качества жизни.",
    des1: "Сбалансированная формула FemBalance, обогащенная витаминами и минералами, помогает нормализовать гормональный фон, улучшить состояние кожи и волос, а также повысить уровень энергии и жизненных сил.Уникальное сочетание природных компонентов, таких как Коллаген гидролизованный, витамин E (DL-альфа-токоферола ацетат) , носитель микрокристаллическая целлюлоза, экстракт шиповника; экстракт ортилии однобокой; экстракт родиолы розовой; корень ашвагандхи;.",
    des2: "Освободите свою женскую энергию с FemBalance!",
    description: [
      "Нормализует гормональный фон и поддерживает женское здоровье.",
      "Улучшает состояние кожи, волос и ногтей.",
      "Повышает уровень энергии и жизненных сил.",
    ],
    rules: [
      { li: "Вы забудете о дискомфорте и неудобствах, связанных с женскими проблемами. Почувствуете легкость и свежесть каждый день, будучи уверенными в своем здоровье и благополучии. Преодолеете болезненные симптомы, вернувшись к активной и радостной жизни. Получите возможность наслаждаться полноценными отношениями и самосознанием, обретя гармонию в себе.      ", },
      { li: "Исправите забытые аспекты и ошибка, избегая дальнейших проблем. Учтите важные детали и сделайте все правильно с первого раза. Не допустите повторения ошибок и обеспечьте успешное завершение процесса.      ", },
      { li: "Восстановите утраченные данные и восстановите поврежденные файлы. Осуществите восстановление системы после сбоя и верните все к исходному состоянию. Обеспечьте восстановление здоровья после травмы и восстановите потерянные функции организма." },
      { li: "Получите доступ к своей медицинской истории в онлайн-кабинете. Получите свои заказанные товары через курьера или в пункте выдачи. Получите консультацию специалиста по интересующему вопросу. Получите доступ к эксклюзивным предложениям и акциям.      " },


    ],
    customerReviews:
      [
        {
          id: 1,
          name: "Екатерина Васильева",
          date: "8 марта 2024",
          content: "FemBalance стал настоящим спасением для меня. Улучшилось общее самочувствие, и я чувствую себя полной энергии. Кожа и волосы также выглядят лучше!",
          helpfulCount: 26,
        },
        {
          id: 2,
          name: "Алина Каримова",
          date: "21 февраля 2024",
          content: "После начала приема FemBalance, я заметила, как мое состояние заметно улучшилось. Гормональный фон стабилизировался, и я почувствовала прилив сил.",
          helpfulCount: 34,
        },
        {
          id: 3,
          name: "Мария Ильинова",
          date: "15 января 2024",
          content: "Использую FemBalance уже два месяца и не могу нарадоваться на результаты. Это действительно работает, и я рекомендую его всем своим подругам.",
          helpfulCount: 19,
        },
        {
          id: 4,
          name: "Дарья Громова",
          date: "2 декабря 2023",
          content: "Удивительно, но FemBalance помог мне восстановить баланс и энергию. Теперь я чувствую себя намного лучше, и мои дни стали ярче и продуктивнее.",
          helpfulCount: 22,
        },
        // Add more review objects as needed
      ],

    listItems: [
      { li: "Менопауза" },
      { li: "ПМС (предменструальный синдром)" },
      { li: "Нарушения менструального цикла" },
      { li: "Гормональный дисбаланс" },
      { li: "Проблемы с кожей" },
    ],
    priceList: [
      {
        price: "74250",
        desc: '',
        pack: fembalanceOne,
        index: 1,

      },
      {
        price: "86400",
        desc: '',
        undiscount: "172800",
        pack: fembalanceTwo,
        index: 2,
      },
      {
        price: "115200",
        desc: '',
        pack: fembalanceThree,
        undiscount: "230400",
        index: 3,
      }
    ]

  }
];
